import { render, staticRenderFns } from "./SelectYourRole.vue?vue&type=template&id=71c9732d&scoped=true"
import script from "./SelectYourRole.vue?vue&type=script&lang=ts&setup=true"
export * from "./SelectYourRole.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./SelectYourRole.vue?vue&type=style&index=0&id=71c9732d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c9732d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/dashboard/core/components/base/image/SvgThemedIcon.vue').default,ComposedFormItemCheckbox: require('/root/dashboard/core/components/composed/form-item/Checkbox.vue').default,BaseButtonSimple: require('/root/dashboard/core/components/base/button/Simple.vue').default,ComposedNavNavigation: require('/root/dashboard/core/components/composed/nav/Navigation.vue').default,FlowsSigninComponentsLanguagesDropdown: require('/root/dashboard/core/components/flows/signin/components/LanguagesDropdown.vue').default,ComposedPopupFullscreenAffility: require('/root/dashboard/core/components/composed/popup/FullscreenAffility.vue').default})
