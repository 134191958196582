
import {mapGetters} from "vuex";
import ApiService from "@/core/services/api-interaction/ApiService";
import CashFTPurchaseCardModal from "~/components/cashFT/cashFTPurchaseCardModal/CashFTPurchaseCardModal.vue";
import SubscriptionBuyModal from "@/pages/nfts/products/components/subscriptionsWeb2Modal/SubscriptionsWeb2Modal";
import { FinishReasonEnum } from '~/core/types/purchase-popup-2/FinishReasonEnum';
import { GETTERS as EMAIL_VERIFICATION_GETTERS } from '~/store/affility/user/flows/email-verification/names';
import { GETTERS as UPGRADE_TO_AFFILIATE_GETTERS } from '~/store/affility/user/flows/upgrade-to-affiliate/names';
import { GETTERS as SUCCESS_MODAL_GETTERS } from '~/store/affility/user/feedback/modals/success/names';
import { GETTERS as TERMS_AND_CONDITIONS_GETTERS } from "~/store/affility/user/flows/terms-and-conditions/names";
import { ACTIONS as AUTH_ACTIONS } from '~/store/affility/user/auth/names';

export default {
    name: "GlobalPopups",
    components: {
        CashFTPurchaseCardModal,
        SubscriptionBuyModal,
    },
    computed: {
        ...mapGetters({
            authUser: "application/driver/authUser",
            isShowCashFTModal: "cashFT/cashFT-KYC-verification/isShowCashFTModal",
            showModalCardType: "cashFT/cashFT-KYC-verification/showModalCardType",
            successModalParams:`affility/user/feedback/modals/success/${SUCCESS_MODAL_GETTERS.AGGREGATED_MODAL_STATE}`,
            isVerifyingEmail: `affility/user/flows/email-verification/${EMAIL_VERIFICATION_GETTERS.IS_VERIFYING_EMAIL}`,
            isEmailVerificationNotificationVisible: `affility/user/flows/email-verification/${EMAIL_VERIFICATION_GETTERS.IS_EMAIL_SENT_NOTIFICATION_VISIBLE}`,
            isUpgradingToAffiliate: `affility/user/flows/upgrade-to-affiliate/${UPGRADE_TO_AFFILIATE_GETTERS.IS_UPGRADING_TO_AFFILIATE}`,
            termsAndConditionsPopupProps: `affility/user/flows/terms-and-conditions/${TERMS_AND_CONDITIONS_GETTERS.AGGREGATED_POPUP_PROPERTIES}`,
        }),
        ...mapGetters('application/popup-control', [
            // UpgradeWeeklyCapDepositWeb2ModalLockedUnlocked popup
            'isWeb2DepositToSPModalVisibleLockedUnlocked',
            'depositToSPWeb2PopupDataLockedUnlocked',
            // PopupsGymSignInPopup popup
            'isConnectWalletPopupVisible',
            // PopupsWeb2MetamaskModalsClaimModal popup
            'isWeb2ClaimPopupVisible',
            'claimWeb2PopupData',
            'contractNameForTransaction',
            // SubscriptionBuyModal popup
            'isWeb2SubscriptionModalVisible',
            'subscriptionWeb2PopupData',
            // PopupsAcceptAffiliateTOSPopup popup
            'affiliateTmsPopupData',
            // UpgradeWeeklyCapDepositWeb2Modal popup
            'isWeb2DepositToSPModalVisible',
            'depositToSPWeb2PopupData',
            // PopupsWeb2MetamaskModalsAllowanceModal popup
            'isWeb2AllowancePopupVisible',
            'allowanceContractAddress',
            // PopupsMetaMaskInstallationPopup popup
            'isMetaMaskInstallationPopupVisible',
            // PopupsConfirmationPopup popup
            'comfirmationPopupData',
            // PopupsLoadingPopup popup
            'loadingPopupData',
            'twoFactorAuthenticationVisible',
        ]),
        ...mapGetters('application/purchase-control', [
            'purchase',
            'startStep',
            'isPurchasePopupVisible',
            'externalPayment',
        ]),
        isEligibleForFreeAutoship() {
            return this.authUser &&
                this.authUser.totalOwnPurchaseAmount >= 100 &&
                (Date.now() - new Date(this.authUser.binaryUserRegistrationDate).getTime()) < (30 * 24 * 60 * 60 * 1000);
        },
        doesNotHaveUsername() {
            return this.authUser && !this.authUser?.username;
        },
        isEmailConfirmationOverdued() {
            return this.authUser && !this.authUser?.isEmailVerified && this.authUser?.emailConfirmationOverdued;
        },
        isRoleSelectionRequired() {
            if (!this.authUser && !this.authUser?.walletAddress) return false;
            return typeof this.authUser.isRoleSelected === 'boolean' && !this.authUser.isRoleSelected;
        }
    },
    methods: {
        handleDepositToSPModalLockedUnlockedClose () {
            this.$store.commit('application/driver/SET_SEND_TX_SUCCESS', -1);
            this.$store.dispatch('application/popup-control/showWeb2DepositToSPPopupLockedUnlocked', false, {root: true});
        },
        handleConnectWalletPopupClose () {
            this.$store.dispatch('application/popup-control/changeConnectWalletPopupStatus', false);
        },
        handleWeb2ModalClose () {
            this.$store.commit('application/driver/SET_SEND_TX_SUCCESS', -1);
            this.$store.dispatch('application/popup-control/showWeb2Popup', false, {root: true});
        },
        handleSubscriptionModalClose () {
            this.$store.commit('application/driver/SET_SEND_TX_SUCCESS', -1);
            this.$store.dispatch('application/popup-control/showWeb2SubscriptionPopup', false, {root: true});
        },
        handleDepositToSPModalClose () {
            this.$store.commit('application/driver/SET_SEND_TX_SUCCESS', -1);
            this.$store.dispatch('application/popup-control/showWeb2DepositToSPPopup', false, {root: true});
        },
        handleAllowanceModalClose () {
            this.$store.dispatch('application/popup-control/showWeb2AllowancePopup', false, {root: true});
        },
        handleMetaMaskInstallationPopupClose () {
            this.$store.dispatch('application/popup-control/openMetaMaskInstallationPopup', false);
        },
        handlePurchasePopupClose() {
            const finishReason = this.$store.state.application['purchase-control'].finishReason;
            if (finishReason === FinishReasonEnum.SUCCESS) {
                this.fetchBinaryUserData();
            }
        },
        fetchBinaryUserData() {
            const timer = setInterval( async () => {
                const responseBinary = await ApiService.get(`${this.$config.binaryTreeApi}/api/users`);
                if (responseBinary && responseBinary?.data?.user) {
                    await this.$store.dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
                        isRoleSelected: responseBinary?.data?.user?.isRoleSelected,
                        currentRankName: responseBinary?.data?.user?.currentRank,
                        commissionRankName: responseBinary?.data?.user?.commissionRank,
                        subscriptionExpiresAt: responseBinary?.data?.subscription_expires_at,
                        isReferralLinkActive: responseBinary?.data?.user?.isReferralLinkActive,
                        isFastStartIncentiveActive: responseBinary?.data?.user?.isFastStartIncentiveActive,
                        totalOwnPurchaseAmount: responseBinary?.data?.user?.totalOwnPurchaseAmount,
                        totalUnilevelTeamTurnoverAmount: responseBinary?.data?.user.totalUnilevelTeamTurnoverAmount,
                        isUsedFastStartIncentive: responseBinary?.data?.user.isUsedFastStartIncentive,
                        isBinaryTreeGuideSeen: responseBinary?.data?.user.isBinaryTreeGuideSeen,
                        firstPurchaseDate: responseBinary?.data?.firstPurchaseDate
                    });
                }
            }, 3000);
            setTimeout(() => {
                clearInterval(timer);
            }, 60000);
        },
    },
}
