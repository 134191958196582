import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import {getClientRuntimeConfig, toWei} from "~/core/helpers/GlobalHelpers";
import ApiService from "~/core/services/api-interaction/ApiService";
import {PopupHelper} from "~/core/helpers/PopupHelper";
import {SubscriptionService} from "~/pages/nfts/products/services/SubscriptionService";
import {MetaInventoryContainer} from "~/core/models/MetaInventoryContainer";
import {RANKS_ORDER} from "~/constants/constants";
import {ACTIONS as AUTH_ACTIONS} from '~/store/affility/user/auth/names';

const $config = getClientRuntimeConfig();
const BINARY_API = `${$config.binaryTreeApi}/api/subscription`;

let subscriptionExpiresAtTimer = null;
let frozenAutoshipExpiresAtTimer = null;

export const state = () => {
    return {
        isSubscriptionLoading: false,
        isClosed: true,
    };
};

export const actions = {
    approveAllowance ({ dispatch }, amount) {
        return dispatch(
            "transactions/send",
            {
                callback: this.$write[this.$metaverseId()].USDT.methods.approve(this.$contracts[this.$metaverseId()].BinarySubscription._address, toWei(amount)),
            },
            { root: true },
        );
    },
    buySubscription ({ dispatch }, { amount, months }) {
        const address = MetaWorldManager.sharedInstance().getWalletAddressForFetchInfo();
        return dispatch(
            "transactions/send",
            {
                callback: this.$write[this.$metaverseId()].BinarySubscription.methods.purchaseSubscription(
                    address,
                    toWei(amount),
                    months,
                ),
            },
            { root: true },
        )
    },

    setClosedEvent ({ commit }, value) {
        commit('SET_CLOSED_EVENT', value);
    },

    updateUserSubscriptionExpires() {
        const currentUserSubscriptionExpiresAt = MetaInventoryContainer.sharedInstance().authUserData?.subscriptionExpiresAt;

        subscriptionExpiresAtTimer = setInterval(async () => {
            const userData = await SubscriptionService.getBinaryUsers();

            if (
                (currentUserSubscriptionExpiresAt && userData.subscription_expires_at > currentUserSubscriptionExpiresAt) ||
                !currentUserSubscriptionExpiresAt && userData.subscription_expires_at
            ) {
                await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
                    subscriptionExpiresAt: userData.subscription_expires_at,
                    activeSubscriptionAbonement: userData?.user?.activeSubscriptionAbonement
                }, { root: true });
                clearInterval(subscriptionExpiresAtTimer);
            }
        }, 2000);

        setTimeout(() => {
            clearInterval(subscriptionExpiresAtTimer);
        }, 60000);
    },
    async getFrozenAutoship({ dispatch, rootState }) {
        const authUserData = rootState?.application?.driver?.authUser;

        if (authUserData && RANKS_ORDER[authUserData?.commissionRankName] <= 4) {
            try {
                const res = await ApiService.get(`${$config.binaryTreeApi}/api/subscription/get-frozen-autoship`);
                const frozenAutoShip = res?.data?.frozenAutoShip;
                await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, { frozenAutoship: frozenAutoShip }, { root: true });
            } catch (e) {
                console.log('frozenAutoShip ', e);
                await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, { frozenAutoship: 0 }, { root: true });
            }
        }
    },
    updateFrozenAutoship({ dispatch, rootState }) {
        const authUserData = rootState?.application?.driver?.authUser;
        const currentUserFrozenAutoship = authUserData?.frozenAutoship;

        frozenAutoshipExpiresAtTimer = setInterval(async () => {
            try {
                const res = await ApiService.get(`${$config.binaryTreeApi}/api/subscription/get-frozen-autoship`);
                const frozenAutoShip = res?.data?.frozenAutoShip;

                if (
                    (currentUserFrozenAutoship && frozenAutoShip > currentUserFrozenAutoship) ||
                    !currentUserFrozenAutoship && frozenAutoShip
                ) {
                    await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, { frozenAutoship: frozenAutoShip }, { root: true });
                    clearInterval(frozenAutoshipExpiresAtTimer);
                }
            } catch (e) {
                console.log('frozenAutoShip ', e);
                clearInterval(frozenAutoshipExpiresAtTimer);
            }
        }, 2000);

        setTimeout(() => {
            clearInterval(frozenAutoshipExpiresAtTimer);
        }, 60000);
    }
};

export const mutations = {
    SET_BINARY_SUBSCRIPTION_LOADING (state, value) {
        state.isSubscriptionLoading = value;
    },
    SET_CLOSED_EVENT (state, value) {
        state.isClosed = value;
    },
};

export const getters = {
    isSubscriptionLoading: ({isSubscriptionLoading}) => isSubscriptionLoading,
    isClosed: ({isClosed}) => isClosed,
};
