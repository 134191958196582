import { render, staticRenderFns } from "./ConnectMyWallet.vue?vue&type=template&id=2b5351df&scoped=true"
import script from "./ConnectMyWallet.vue?vue&type=script&lang=ts&setup=true"
export * from "./ConnectMyWallet.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ConnectMyWallet.vue?vue&type=style&index=0&id=2b5351df&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b5351df",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButtonSimple: require('/root/dashboard/core/components/base/button/Simple.vue').default})
