
import { PRODUCT_TYPES } from "~/core/services/utils/Constants";
import { BTCSingleProductsIds, BTCSuperBundlesIds } from "~/store/affility/pages/shop/SuperBundleTypes";

export default {
    name: "NftImageComponent",
    props: {
        nftType: {
            type: String,
            required: true,
        },
        purchaseInfo: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            PRODUCT_TYPES
        }
    },
    computed: {
        bundleData() {
            return this.purchaseInfo?.bundleData
        },
        getBundleDataClass() {
            if (this.nftType === PRODUCT_TYPES.BUNDLE && this.bundleData) {
                if (this.bundleData.numberOfParcels) {
                    return `image-${this.PRODUCT_TYPES.PARCEL}`
                } else {
                    return `image-basic-${PRODUCT_TYPES.BUNDLE}`
                }
            } else {
                return `image-${this.nftType}`
            }
        },
        isBtcBundle() {
            return BTCSuperBundlesIds.includes(this.purchaseInfo.bundle_sc_id);
        },
        isBtcSingeProduct() {
            return BTCSingleProductsIds.includes(this.purchaseInfo.bundle_sc_id);
        },
        getImage() {
            if (this.isBtcBundle) {
                return require("~/assets/images/gymstreet/parcels/gymnet-btc/parcel-t-20.webp");
            } else {
                return require("~/assets/images/gymstreet/parcels/gymnet/parcel-t-20.webp");
            }
        },
    }
}
