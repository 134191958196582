import {
    AffilityParcelHashPower,
    AffilityStarterBundle,
    AffilitySuperBundlesIds, BTCSingleProductsIds,
    BTCSuperBundlesIds
} from "~/store/affility/pages/shop/SuperBundleTypes";
import { PriceFilterTypes } from "~/pages/shop/types/PriceFilterTypes";

export class MetaBundle {
    selectedToken = PriceFilterTypes.USDT;

    constructor (jsonData) {
        this.smartContractId = jsonData.sc_id;
        this.name = jsonData.name;
        this.numberOfParcels = jsonData.number_of_parcels;
        this.price = parseFloat(jsonData.price);
        this.discountedPrice = parseFloat(jsonData.discounted_price);
        this.commissionVolume = parseFloat(jsonData.commission_volume);
        this.benefitsInfo = jsonData.benefitsInfo;
        this.terahashValue = jsonData.terahashValue;
        this.utilityPrice = new MetaBundleSplitPrice(jsonData.splitPrices);
    }

    isSuperBundle() {
        return this.smartContractId && [...AffilitySuperBundlesIds, ...BTCSuperBundlesIds].includes(this.smartContractId);
    }

    isBTCSingleProduct() {
        return this.smartContractId && BTCSingleProductsIds.includes(this.smartContractId);
    }

    isStarterBundle() {
        return this.smartContractId === AffilityStarterBundle;
    }

    get getHashPower () {
        if (this.numberOfParcels && this.numberOfParcels > 0) {
            return this.numberOfParcels * AffilityParcelHashPower;
        } else {
            return 0;
        }
    }

    get image() {
        if (BTCSuperBundlesIds.includes(this.smartContractId)) {
            // BTC SUPER BUNDLES IMAGE
            try {
                return require(`@/assets/affility/images/product/btc/super-bundles/super-bundle-${this.smartContractId}.webp`);
            } catch {
                return require('@/assets/affility/images/product/affility/parcels/parcel.webp');
            }
        } else if (BTCSingleProductsIds.includes(this.smartContractId)) {
            // BTC SINGLE PRODUCT IMAGE
            try {
                return require(`@/assets/affility/images/product/btc/single-products/single-product-${this.smartContractId}.webp`);
            } catch {
                return require('@/assets/affility/images/product/btc/single-products/single-product.webp');
            }
        } else if (AffilitySuperBundlesIds.includes(this.smartContractId) || this.isStarterBundle()) {
            // AFFILITY SUPER BUNDLES IMAGE
            try {
                return require(`@/assets/affility/images/product/affility/super-bundles/super-bundle-${this.smartContractId}.webp`);
            } catch {
                return require('@/assets/affility/images/product/affility/parcels/parcel.webp');
            }
        } else {
            // SOMETHING ELSE
            return require('@/assets/affility/images/product/affility/parcels/parcel.webp');
        }
    }
}

export class MetaBundleSplitPrice {
    constructor (jsonData) {
        this.usdt = jsonData?.usdt || 0;
        this.gymnet = jsonData?.gymnet || 0;
        this.commissionVolume = jsonData?.commission_volume || 0;
    }
}
