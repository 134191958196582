import { render, staticRenderFns } from "./Maintenance.vue?vue&type=template&id=2c449dfe&scoped=true"
import script from "./Maintenance.vue?vue&type=script&lang=ts&setup=true"
export * from "./Maintenance.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./Maintenance.vue?vue&type=style&index=0&id=2c449dfe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c449dfe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseCountdownSimple: require('/root/dashboard/core/components/base/countdown/Simple.vue').default,FlowsSigninComponentsLanguagesDropdown: require('/root/dashboard/core/components/flows/signin/components/LanguagesDropdown.vue').default,ComposedButtonSupport: require('/root/dashboard/core/components/composed/button/Support.vue').default})
