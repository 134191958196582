

import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import NftImageComponent from './components/nft-image/NftImageComponent.vue';
import StepIndicator from '~/components/utils/indicators/step-indicator/StepIndicator.vue';
import GymNewButton from '~/components/utils/form-components/GymNewButton.vue';
import TotalWidget from '../../components/total-widget/TotalWidget.vue';
import PurchaseExternalWidget from '../../components/purchase-external-widget/PurchaseExternalWidget.vue';
import {toCurrencyFormat, toNumericFormat} from '@/core/helpers/GlobalHelpers';
import {PaymentMethodEnum} from '~/core/types/purchase-popup-2/PaymentMethodEnum';
import {UserBalance} from '~/core/models/purchase-popup-2/UserBalance';
import MunicipalityTx from '~/core/models/purchase-popup-2/MunicipalityTx';
import Web3MunicipalityTx from '~/core/models/purchase-popup-2/Web3MunicipalityTx';
import {ExternalWalletPayment} from '~/core/models/purchase-popup-2/ExternalWalletPayment';
import {PaymentStatus} from '~/core/types/purchase-popup-2/PaymentStatus';
import {PaymentService} from '~/core/services/PaymentService';
import {RawExternalWalletPayment} from '~/core/types/purchase-popup-2/RawExternalWalletPayment';
import {UseExtraBalancesTypes} from "~/core/models/purchase-popup-2/UseExtraBalancesTypes";

const BUSD_FEE_VALUE = 3;
const PAYMENT_UPDATE_INTERVAL = 12000;
// TODO remove unused props, data and methods
export default defineComponent({
    name: 'PurchaseSummary',
    components: {
        NftImageComponent,
        StepIndicator,
        GymNewButton,
        TotalWidget,
        PurchaseExternalWidget,
    },
    props: {
        purchase: {
            type: Object,
            required: true,
        },
        userBalance: {
            type: UserBalance,
            default: null,
        },
        transactionObject: {
            type: [MunicipalityTx, Web3MunicipalityTx, ExternalWalletPayment],
            required: true,
        },
        paymentMethod: {
            type: String,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'onCryptoSentClick',
        'onClickBack',
        'onStartLoading',
        'onStopLoading',
        'onMissingCryptoAmount',
        'onSuccessFullPayment',
    ],
    data () {
        return {
            gasFeeVisible: false,
            BUSD_FEE_VALUE,
            PaymentMethodEnum,
            toCurrencyFormat,
            enabledJustNow: false,
            utilitySwitchedJustNow: false,
            gymnetSwitchedJustNow: false,
            UseExtraBalancesTypes
        };
    },
    computed: {
        ...mapGetters('application/purchase-control', [
            'externalPayment',
        ]),
        utilityAsset() {
            return this.transactionObject._isUsingExtraBalance === UseExtraBalancesTypes.BALANCE_GYMNET
                ? this.transactionObject.priceInfo.splitAmountGymnet.gymnet
                : this.transactionObject.priceInfo.splitAmountUtility.gymnet;
        },
        busdAsset() {
            if (this.transactionObject.isUsingExtraBalance) {
                return this.transactionObject._isUsingExtraBalance === UseExtraBalancesTypes.BALANCE_GYMNET
                    ? this.transactionObject.priceInfo.splitAmountGymnet.usdt
                    : this.transactionObject.priceInfo.splitAmountUtility.usdt;
            }
            return this.transactionObject.priceInfo.totalAmount;
        },
        isPaymentMethodExternal () {
            return this.paymentMethod === PaymentMethodEnum.EXTERNAL;
        },
        isShowBackButton () {
            return !this.readonly;
        },
        isShowPurchaseButton () {
            return !this.readonly || this.isPaymentStatusCreated;
        },
        isWithEnablePayment () {
            return !this.isPaymentMethodExternal;
        },
        isPaymentMethodInternal () {
            return this.paymentMethod === PaymentMethodEnum.INTERNAL;
        },
        hasEnoughBNBFeeBalance () {
            return this.userBalance.bnb.value >= this.transactionObject.priceInfo.gasAmount.value;
        },
        hasEnoughBUSDFeeBalance () {
            return Number(this.userBalance.usdt.value) >= Number(this.busdAsset.value) + BUSD_FEE_VALUE;
        },
        currentStep () {
            if (this.transactionObject.isEnabled) {
                return 2;
            }
            return 1;
        },
        isPaymentStatusCompleted () {
            return this.transactionObject.isCompleted;
        },
        isPaymentStatusCreated () {
            return this.transactionObject.isCreated;
        },
        isUtilityBalanceSufficient() {
            return this.userBalance.utility.value >= this.transactionObject.priceInfo.splitAmountUtility.gymnet.value
                && this.transactionObject.canUseUtility;
        },
        isGymnetBalanceSufficient() {
            return this.userBalance.gymnet.value >= this.transactionObject.priceInfo.splitAmountGymnet.gymnet.value
                && this.transactionObject.canUseGymnet;
        },
        getAlternativelyUsingText() {
            let currency_yg;
            if (this.isUtilityBalanceSufficient && this.isGymnetBalanceSufficient) {
                currency_yg = `Utility ${this.$t('Or').toLowerCase()} Gymnet`
            } else if (this.isUtilityBalanceSufficient && !this.isGymnetBalanceSufficient) {
                currency_yg = 'Utility';
            } else {
                currency_yg = 'Gymnet';
            }

            return this.$t('Alternatively, you can use your CURRENCY balance to cover the gas fee.', {
                currency_yg
            })
        }
    },
    watch: {
        'transactionObject.isEnabled': {
            handler () {
                setTimeout(() => {
                    this.gasFeeVisible = true;
                }, 100);
            },
            immediate: true,
        },
    },
    mounted () {
        setTimeout(() => {
            this.gasFeeVisible = true;
        }, 100);
        if(this.paymentMethod === PaymentMethodEnum.EXTERNAL) {
            this.setupScanInterval();
        }
    },
    beforeDestroy() {
        this.scanInterval = false;
    },
    methods: {
        toNumericFormat,
        handleISentTheCrypto () {
            this.gasFeeVisible = false;
            this.$emit('onCryptoSentClick');
        },
        async handleEnablePayment () {
            this.gasFeeVisible = false;
            try {
                this.$emit('onStartLoading');

                if (!this.transactionObject.isUsdtEnabled) {
                    await this.transactionObject.approve();
                }

                if (this.transactionObject.isUsingExtraGymnetBalance && !this.transactionObject.isGymnetEnabled) {
                    await this.transactionObject.approveGymnet();
                }

                this.transactionObject.clearCache();
                await this.transactionObject.estimateGas();
                this.enabledJustNow = true;
                this.$emit('onStopLoading');
            } catch (e) {
                this.$emit('onStopLoading', e);
            } finally {
                this.gasFeeVisible = true;
            }
        },
        async handlePay () {
            this.gasFeeVisible = false;
            try {
                this.$emit('onStartLoading');
                await this.transactionObject.send();
                this.$emit('onSuccessFullPayment');
                this.$emit('onStopLoading');
            } catch (e) {
                this.$emit('onStopLoading', e);
            } finally {
                this.gasFeeVisible = true;
            }
        },
        async setupScanInterval () {
            this.scanInterval = true;
            const paymentUpdateGenerator = PaymentService.listenForPayment(
                this.transactionObject.paymentId,
                PAYMENT_UPDATE_INTERVAL,
                () => this.scanInterval,
            );
            for await (const payment of paymentUpdateGenerator) {
                if(payment) {
                    const rawPayment = payment as RawExternalWalletPayment;
                    if (rawPayment.status === PaymentStatus.EXPIRED ||
                        rawPayment.status === PaymentStatus.CREATED && Number(rawPayment.deviation_amount) < 0 ||
                        rawPayment.grant_transaction_hash !== null) {
                        this.scanInterval = false;
                        if(this.externalPayment || this.transactionObject === this.externalPayment) {
                            this.$store.commit('application/purchase-control/SET_EXTERNAL_PAYMENT',
                                ExternalWalletPayment.fromPaymentAndNewRawData(
                                    this.transactionObject,
                                    rawPayment,
                                )
                            );
                        } else {
                            this.transactionObject.rawPaymentData = rawPayment;
                        }
                        if(rawPayment.status === PaymentStatus.EXPIRED ||
                            rawPayment.status === PaymentStatus.CREATED && Number(rawPayment.deviation_amount) < 0) {
                            this.$emit('onMissingCryptoAmount');
                        } else if (rawPayment.grant_transaction_hash !== null) {
                            this.$emit('onSuccessFullPayment');
                        }
                    }
                }
            }
        },
        async useExtraBalance(extraType: UseExtraBalancesTypes) {
            if (extraType === UseExtraBalancesTypes.BALANCE_GYMNET) {
                this.gymnetSwitchedJustNow = true;
                const gymnetAmountToCheck = this.transactionObject.priceInfo.splitAmountGymnet.gymnet.value;
                await this.transactionObject.checkGymnetAllowance(gymnetAmountToCheck);
            } else {
                this.utilitySwitchedJustNow = true;
            }
            this.transactionObject.setExtraBalance(extraType);
        },
    },
});

