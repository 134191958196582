import BigNumber from 'bignumber.js';
import BNBAsset from '~/pages/finances/wallet/models/BNBAsset';
import WalletAddress from '~/pages/finances/wallet/models/WalletAddress';
import {fromWei, getClientRuntimeConfig, toBN} from '@/core/helpers/GlobalHelpers';
import ApiService from '~/core/services/api-interaction/ApiService';
import type { PurchaseTransaction } from '~/core/types/purchase-popup-2/PurchaseTransaction';
import { GlobalMakerService } from '~/core/services/GlobalMakerService';
import { Contract } from 'web3-eth-contract';
import { MetaWorldManager } from '~/core/services/map/MetaWorldManager';
import { PriceInfo } from './PriceInfo';
import { PaymentType } from './PaymentType';
import Asset from '~/pages/finances/wallet/models/Asset';
import { BundleService } from '~/pages/dashboard/services/BundleService';
import ExchangeService from '~/pages/finances/wallet/services/ExchangeService';
import { CurrencyName } from '~/pages/finances/wallet/models/CurrencyName';
import {BUNDLE_TYPE_STARTER_BUNDLE, PRODUCT_TYPES, SINGLE_PARCEL} from '~/core/services/utils/Constants';
import { AllowanceInfo } from './AllowanceInfo';
import { UseExtraBalancesTypes } from "~/core/models/purchase-popup-2/UseExtraBalancesTypes";
import { AffilitySuperBundlesIds } from "~/store/affility/pages/shop/SuperBundleTypes";

const GAS_FEE_MULTIPLIER_FOR_INTERNAL = 1.3; // multiplier for gas fee for internal transactions, to sync with backend

const $config = getClientRuntimeConfig();
const BINARY_TREE_API = `${$config.binaryTreeApi}/api`;

export default class MunicipalityTx implements PurchaseTransaction {
    protected cachedGasFee: BNBAsset | null = null;
    protected cachedGasPrice: BigNumber | null = null;
    protected _isUsingExtraBalance: UseExtraBalancesTypes | null = null;
    protected _quantity = 1;
    protected _bundleType = null;
    protected owner = null;
    protected lastAllowance: AllowanceInfo = null;
    protected lastGymnetAllowance: AllowanceInfo = null;

    constructor (
        public _currency: Contract,
        public _gymnet: Contract,
        public _municipality: Contract,
        public readonly method: string,
        public args: Array<unknown>,
        public account: WalletAddress,
        public priceInfo?: PriceInfo,
    ) {
        this.setArgs(args);
    }

    async estimateGas (): Promise<BNBAsset> {
        if (this.priceInfo.totalAmount.currency === 'PAP') return;

        const metaWorldManager = MetaWorldManager.sharedInstance();
        const { account: outgoingAccount } = this;
        if(this.cachedGasFee) {
            return this.cachedGasFee;
        }
        let estimatedGas = 0;
        try {
            estimatedGas = await this._municipality.methods[this.method](...this.args)
                .estimateGas({
                    from: outgoingAccount,
                });
        } catch (e) {
            console.log('Estimation for regular failed!');
            console.log(e);
        }
        if (this.canUseGymnetOrUtility) {
            const estimateGasForGymnetOrUtility = async (type: PaymentType) => {
                const name = type === PaymentType.UTILITY ? 'Utility' : 'Gymnet';
                console.log(`Estimating for ${name}!`);
                try {
                    const utilityArgs = [...this.args];
                    utilityArgs.splice(3, 1, type);
                    estimatedGas = await this._municipality.methods[this.method](...utilityArgs)
                        .estimateGas({
                            from: outgoingAccount,
                        });
                } catch (e) {
                    console.log(`Estimation for ${name} failed!`);
                }
            }

            if (!estimatedGas) {
                await estimateGasForGymnetOrUtility(PaymentType.UTILITY);
            }
            if (!estimatedGas) {
                await estimateGasForGymnetOrUtility(PaymentType.GYMNET);
            }
        }
        const readWeb3 = metaWorldManager.readWeb3();
        const gasPrice = this.cachedGasPrice ?? new BigNumber(await readWeb3.eth.getGasPrice());
        const bigNumberEstimatedGas = toBN(Math.ceil(estimatedGas * GAS_FEE_MULTIPLIER_FOR_INTERNAL));
        const gasInBNB = bigNumberEstimatedGas.mul(toBN(gasPrice));
        const gasInBNBValue = fromWei(gasInBNB.toString());
        const gasAmount = new BNBAsset(Number(gasInBNBValue), 0, false, metaWorldManager);
        if (this.priceInfo) {
            this.priceInfo.gasAmount = gasAmount;
        }
        this.cachedGasFee = gasAmount;
        this.cachedGasPrice = gasPrice;
        return this.priceInfo.gasAmount;
    }

    async send () {
        const transmissionData = {
            to: this._municipality.options.address,
            value: 0,
            data: this._municipality.methods[this.method](...this.args).encodeABI(),
        };
        return await ApiService.post('internal-wallet/send-tx', transmissionData);
    }

    async sendPAP() {
        const payload = {
            to: this.owner,
            product_id: this._bundleType || SINGLE_PARCEL,
            quantity: this._quantity
        };

        try {
            const purchaseResponse = await ApiService.post(`${BINARY_TREE_API}/purchases/pap`, payload);

            const { id } = purchaseResponse?.data;

            return new Promise((resolve, reject) => {
                const intervalId = setInterval(async () => {
                    try {
                        const statusResponse = await ApiService.get(`${BINARY_TREE_API}/purchases/pap/${id}`);
                        const { status } = statusResponse?.data;

                        if (status === 'Completed') {
                            clearInterval(intervalId);
                            resolve(true);
                        } else if (status !== 'Pending') {
                            clearInterval(intervalId);
                            reject(new Error('Purchase failed'));
                        }
                    } catch (error) {
                        clearInterval(intervalId);
                        reject(error);
                    }
                }, 1000);
            });
        } catch (error) {
            throw new Error('Failed to send PAP: ' + error.message);
        }
    }

    async approve(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            let isApprove = false;
            const municipalityAddress = this._municipality.options.address;
            const allowanceAmount = Number(1e24).toLocaleString('fullwide', { useGrouping: false });
            const transactionObject = {
                to: this._currency.options.address,
                value: 0,
                data: this._currency.methods.approve(municipalityAddress, allowanceAmount).encodeABI(),
            };

            try {
                isApprove = await GlobalMakerService.$store.dispatch(
                    'application/driver/internalWalletSendTx',
                    transactionObject
                );

                if (isApprove) {
                    this.lastAllowance = new AllowanceInfo(
                        Number(1000000),
                        allowanceAmount,
                        this.priceInfo.totalAmount.value,
                        true
                    );
                    resolve(true);
                } else {
                    reject(new Error('Approve failed'));
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    async approveGymnet(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            let isApprove = false;
            const municipalityAddress = this._municipality.options.address;
            const allowanceAmount = Number(1e24).toLocaleString('fullwide', { useGrouping: false });
            const transactionObject = {
                to: this._gymnet.options.address,
                value: 0,
                data: this._gymnet.methods.approve(municipalityAddress, allowanceAmount).encodeABI(),
            };

            try {
                isApprove = await GlobalMakerService.$store.dispatch(
                    'application/driver/internalWalletSendTx',
                    transactionObject
                );

                if (isApprove) {
                    this.lastGymnetAllowance = new AllowanceInfo(
                        Number(1000000),
                        allowanceAmount,
                        this.priceInfo.splitAmountGymnet.gymnet.value,
                        true
                    );
                    resolve(true);
                } else {
                    reject(new Error('Gymnet approval failed'));
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    async checkAllowance (amount: number) {
        if (this.priceInfo.totalAmount.currency === 'PAP') return true;

        const { account: outgoingAccount } = this;
        const allowanceWei = await this._currency.methods
            .allowance(outgoingAccount, this._municipality.options.address)
            .call();
        const allowanceEth = fromWei(allowanceWei) as string;
        const isSufficient = parseFloat(allowanceEth) >= amount;
        this.lastAllowance = new AllowanceInfo(
            Number(allowanceEth),
            allowanceWei,
            amount,
            isSufficient,
        );
        return isSufficient;
    }

    async checkGymnetAllowance (amount: number) {
        const { account: outgoingAccount } = this;
        const allowanceWei = await this._gymnet.methods
            .allowance(outgoingAccount, this._municipality.options.address)
            .call();
        const allowanceEth = fromWei(allowanceWei) as string;
        const isSufficient = parseFloat(allowanceEth) >= amount;
        this.lastGymnetAllowance = new AllowanceInfo(
            Number(allowanceEth),
            allowanceWei,
            amount,
            isSufficient,
        );
        return isSufficient;
    }

    public clearCache() {
        this.cachedGasFee = null;
        this.cachedGasPrice = null;
    }

    public switchOwner(newOwner: string) {
        if (this.method === 'web3PurchaseStarterBundle') {
            this.args.splice(1, 1, newOwner);
        } else if (this.method !== 'purchaseSingleProduct') {
            this.args.splice(2, 1, newOwner);
        }
        this.owner = newOwner;
    }

    public setExtraBalance(extraBalance: UseExtraBalancesTypes | null) {
        if(this.canUseGymnetOrUtility) {
            let extraPayment;
            switch (extraBalance) {
                case UseExtraBalancesTypes.BALANCE_GYMNET:
                    extraPayment = PaymentType.GYMNET;
                    break;
                case UseExtraBalancesTypes.BALANCE_UTILITY:
                    extraPayment = PaymentType.UTILITY;
                    break;
                default:
                    extraPayment = PaymentType.REGULAR;
            }
            this.args.splice(3, 1, extraPayment);
            this._isUsingExtraBalance = extraBalance;
        }
    }

    public setArgs(args: Array<unknown>) {
        this.args = args;
        if(this.method === 'web3MintParcels' || this.method === 'web3MintMiners') {
            this._quantity = args[0] as number;
        } else if (this.method === 'web3PurchaseStarterBundle') {
            this._bundleType = BUNDLE_TYPE_STARTER_BUNDLE;
        } else {
            this._bundleType = args[0] as number;
        }
        if (this.method === 'web3PurchaseStarterBundle') {
            this.owner = args[1] as string;
        } else {
            this.owner = args[2] as string;
        }
    }

    public async updateSelectedCurrency(isUsingPap = false) {
        const isSuperBundle = this.method === 'web3PurchaseSuperBundle';
        let price = this.priceInfo.totalAmount.value;

        if(isSuperBundle) {
            const { discountPrice } = await this._municipality.methods
                .getPriceForSuperBundle(this._bundleType, this.owner, PaymentType.REGULAR)
                .call();
            price = Number(fromWei(discountPrice));
        }

        if (isUsingPap) {
            this.priceInfo.totalAmount = new Asset(
                null,
                "PAP",
                "PAP",
                require('~/assets/images/gymstreet/currencies/Subscription.svg'),
                price,
                1,
                false,
                MetaWorldManager.sharedInstance(),
                "Binance Smart Chain (BEP20)",
            );
        } else {
            this.priceInfo.totalAmount = new Asset(
                null,
                "Tether USD",
                'USDT',
                require('~/assets/images/wallets/usdt.png'),
                price,
                1,
                false,
                MetaWorldManager.sharedInstance(),
                "Binance Smart Chain (BEP20)",
            );
        }
    }

    public async updatePriceInfo() {
        const isParcel = this.method === 'web3MintParcels';
        const isSuperBundle = this.method === 'web3PurchaseSuperBundle';
        if(isSuperBundle) {
            const { discountPrice } = await this._municipality.methods
                .getPriceForSuperBundle(this._bundleType, this.owner, PaymentType.REGULAR)
                .call();
            this.priceInfo.totalAmount = new Asset(
                null,
                "Tether USD",
                'USDT',
                require('~/assets/images/wallets/usdt.png'),
                Number(fromWei(discountPrice)),
                1,
                false,
                MetaWorldManager.sharedInstance(),
                "Binance Smart Chain (BEP20)",
            );
        }
        if(this.canUseGymnetOrUtility) {
            let utilityGymnetPriceInfo = null;
            const gymnetRatePromise = ExchangeService.getRate("GYMNET");
            if(isParcel) {
                utilityGymnetPriceInfo = await BundleService.fetchParcelUtilityPrice(this._quantity, this._municipality);
            } else {
                utilityGymnetPriceInfo = await BundleService.getUtilityPrices(
                        [{ typeID: this._bundleType, isSuper: isSuperBundle }],
                        this._municipality,
                        this.owner
                    );
                utilityGymnetPriceInfo = utilityGymnetPriceInfo.get(this._bundleType);
            }
            const metaWorldManager = MetaWorldManager.sharedInstance();
            const gymnetRate = await gymnetRatePromise;

            const usdtAsset = new Asset(
                null,
                "Tether USD",
                'USDT',
                require('~/assets/images/wallets/usdt.png'),
                utilityGymnetPriceInfo.usdt,
                1,
                false,
                metaWorldManager,
                "Binance Smart Chain (BEP20)",
            );

            const utilityPriceAssets = {
                usdt: usdtAsset,
                gymnet: new Asset(
                    null,
                    "Utility",
                    "Utility" as CurrencyName,
                    require('~/assets/images/gymstreet/currencies/utility-balance.svg'),
                    utilityGymnetPriceInfo.gymnet,
                    gymnetRate,
                    false,
                    metaWorldManager
                ),
            }

            const gymnetPriceAssets = {
                usdt: usdtAsset,
                gymnet: new Asset(
                    null,
                    "Gym Network Token",
                    "GYMNET" as CurrencyName,
                    require("~/assets/images/icons/gymnet-icon.svg"),
                    utilityGymnetPriceInfo.gymnet,
                    gymnetRate,
                    false,
                    metaWorldManager
                ),
            }

            this.priceInfo = new PriceInfo(
                this.priceInfo.totalAmount,
                utilityPriceAssets,
                gymnetPriceAssets,
                this.priceInfo.gasAmount,
            );
        }
        return this.priceInfo;
    }

    get canUsePAP() {
        const methodCheck = ['web3MintParcels'].includes(this.method);
        const productCheck = AffilitySuperBundlesIds.includes(this._bundleType);
        return methodCheck || productCheck;
    }

    get canUseUtility() {
        const methodCheck = ['web3MintParcels'].includes(this.method);
        const productCheck = AffilitySuperBundlesIds.includes(this._bundleType);
        return methodCheck || productCheck;
    }

    get canUseGymnet() {
        return [
            'web3MintParcels',
            'web3PurchaseSuperBundle',
        ].includes(this.method)
    }

    get canUseGymnetOrUtility() {
        return this.canUseGymnet || this.canUseUtility;
    }

    get isBundle() {
        return [
            'web3PurchaseBasicBundle',
            'web3PurchaseParcelsBundle',
            'web3PurchaseMinersBundle',
            'web3PurchaseSuperBundle',
            'web3PurchaseStarterBundle',
            'web3PurchaseCardBundle',
        ].includes(this.method);
    }

    get isParcel() {
        return this.method === 'web3MintParcels';
    }

    get isMiner() {
        return this.method === 'web3MintMiners';
    }

    get bundleType() {
        if (this.isBundle) {
            return this._bundleType;
        }
        return null;
    }

    get quantity() {
        if (!this.isBundle) {
            return this._quantity;
        }
        return null;
    }

    get productType() {
        if (this.isBundle) {
            return PRODUCT_TYPES.BUNDLE;
        }
        if (this.isParcel) {
            return PRODUCT_TYPES.PARCEL;
        }
        if (this.isMiner) {
            return PRODUCT_TYPES.MINER;
        }
    }

    get isUsingExtraBalance() {
        return !!this._isUsingExtraBalance;
    }

    get isGymnetEnabled() {
        return this.lastGymnetAllowance?.isSufficient;
    }

    get isUsdtEnabled() {
        return this.lastAllowance?.isSufficient;
    }

    get isUsingExtraGymnetBalance() {
        return this.isUsingExtraBalance && this._isUsingExtraBalance === UseExtraBalancesTypes.BALANCE_GYMNET;
    }

    get isEnabled() {
        if (this.priceInfo.totalAmount.currency === 'PAP') return true;

        return ( this.isUsingExtraGymnetBalance && this.isGymnetEnabled && this.isUsdtEnabled ) ||
            ( !this.isUsingExtraGymnetBalance && this.isUsdtEnabled);
    }

    public static fromMunicipalityTx(tx: MunicipalityTx) {
        const municipalityTx = new MunicipalityTx(
            tx._currency,
            tx._gymnet,
            tx._municipality,
            tx.method,
            tx.args,
            tx.account,
            tx.priceInfo,
        );
        municipalityTx.setExtraBalance(tx._isUsingExtraBalance);
        municipalityTx.switchOwner(tx.owner);
        return municipalityTx;
    }
}
